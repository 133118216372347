(function(){

	function Upload( file ){
		if( !$('[data-upload]').is('[multiple]') ){
			$('[data-uploads]').html('');
		}
		var xhr = new XMLHttpRequest();
		if( xhr.upload ){
			//Specify uploads container and form
			var upload = $('[data-upload]');
			console.log( upload.data('upload') );
			var uploads = $('[data-uploads]');
			var form = uploads.closest('form');
			var display = uploads.data('display') ? uploads.data('display') : 'upload';
			$('button[type="submit"]', form).each( function(){
				$(this).data( 'previous', $(this).html() ).attr( 'data-previous', $(this).html() );
				$(this).html( 'Uploading...&nbsp;&nbsp;<i class="fa fa-cog fa-spin"></i>' );
				$(this).prop( 'disabled', true );
			});
			if( uploads.is('[data-table]') ){
				var table = true;
			}

			//Create Progress Bar for Upload (Memory)
			var progress = $( '<div class="clearfix text-center">' +
							  	'<i class="fa fa-cog fa-spin fa-4x"></i><br/>' +
							  	'Uploading: "' + file.name + '"...' +
							  	'<div class="progress" role="progressbar" tabindex="0" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">' +
							  		'<div class="progress-meter">' +
							  			'<p class="progress-meter-text">0%</p>' +
							  		'</div>' +
							  	'</div>' +
							  '</div>' );
			var meter = $( '.progress-meter', progress );
			var label = $( '.progress-meter-text', progress );
			//Append the new upload progress to the uploads container
			uploads.append( progress );

			xhr.upload.addEventListener( 'load', function(e){
				meter.width('100%');
				label.html('Upload Complete!');
			}, false );

			xhr.upload.addEventListener('progress', function(e){
				var percent = parseInt( ( e.loaded / e.total * 100 ) );
				meter.width( percent + "%" );
				label.html( percent + "%" );
			}, false);

			xhr.onreadystatechange = function(e){
				if( xhr.readyState == 4 ){
					if( xhr.status == 200 ){
						if( xhr.responseText != '' ){
							var link = JSON.parse(xhr.responseText);
							if( table ){
								$.each(link, function( index, value ){
									var row = $( '<tr class="' + display + '">' +
													'<td><img src="' + value.image + '" class="table-preview"/></td>' +
													'<td>Not Set</td>' +
													'<td>Now</td>' +
													'<td>0</td>' +
													'<td>0</td>' +
													'<td class="actions">' +
														'<a href="/quote/build/' + value.id + '" class="button success" title="Edit Plan" data-notip=""><i class="fa fa-pencil"></i></a>&nbsp;' +
														'<a data-modal="/quote/delete/plan/' + value.id + '" class="button alert" title="Delete Plan" data-notip=""><i class="fa fa-trash"></i></a>' +
													'</td>' +
												'</tr>' );
									progress.before( row );
								} );
							}else{
								var preview = $( '<div class="' + display + '"></div>' );
								preview.append( '<img src="' + link + '" class="preview"/>' +
												'<input type="hidden" name="upload[]" value="' + link + '" />' +
												'<button type="button" class="button alert" data-remove><i class="fa fa-times"></i></button>' );
								progress.before( preview );
							}
							progress.remove();
						}else{
                            progress.before( '<div class="alert callout columns" data-closable>' +
                            				 	'<p class="no-margin">Error occured whilst uploading file! Please try again.</p>' +
                            				 	'<button class="close-button" aria-label="Dismiss Error" type="button" data-close>' +
                            				 		'<span aria-hidden="true"><i class="fa fa-times"></i></span>' +
                            				 	'</button>' +
                            				 '</div>' );
                            progress.remove();
						}
					}else{
                        progress.before( '<div class="alert callout columns" data-closable>' +
                        				 	'<p class="no-margin">Error occured whilst uploading file! Please try again.</p>' +
                        				 	'<button class="close-button" aria-label="Dismiss Error" type="button" data-close>' +
                        				 		'<span aria-hidden="true"><i class="fa fa-times"></i></span>' +
                        				 	'</button>' +
                        				 '</div>' );
                        progress.remove();
					}
					$('button[type="submit"]', form).each( function(){
						$(this).prop( 'disabled', false ).html( $(this).data('previous') );
					});
				}
			};

			//Upload
			xhr.open( "POST", upload.data('upload'), true );
			xhr.setRequestHeader( "Accept", "application/json" );
			xhr.setRequestHeader( "X-FILENAME", file.name );
			xhr.send( file );

		}
	}

	function Init(){
		var xhr = new XMLHttpRequest();
		if( xhr.upload ){
			$(window).on('drop dragdrop', function(e){
                e.stopPropagation();
                e.preventDefault();
				var data = e.dataTransfer || ( e.originalEvent && e.originalEvent.dataTransfer );
				var files = e.target.files || ( data && data.files );
				$('#dragndrop').hide();
				for( var i = 0, f; f = files[i]; i++ ){
					Upload( f );
					if( !$('[data-upload]').is('[multiple]') ) break;
				}
			});
			$('[data-upload]').on('change', function(e){
                e.stopPropagation();
                e.preventDefault();
				var data = e.dataTransfer || ( e.originalEvent && e.originalEvent.dataTransfer );
				var files = e.target.files || ( data && data.files );
				for( var i = 0, f; f = files[i]; i++ ){
					Upload( f );
					if( !$('[data-upload]').is('[multiple]') ) break;
				}
				$('[data-upload]').wrap('<form>').closest('form').get(0).reset();
				$('[data-upload]').unwrap();
			});
		}
		var flicker;
		$(window, '#dragndrop').on('dragenter dragover', function(e){
			clearTimeout( flicker );
            e.stopPropagation();
            e.preventDefault();
			$('#dragndrop').show();
		});
		$(window, '#dragndrop').on('dragleave', function(e){
            e.stopPropagation();
			flicker = setTimeout(function(){
				$('#dragndrop').hide();
			}, 25);
		});
		$(window).on('drop', function(e){
			$('#dragndrop').hide();
		});
		$(document).on('click', '[data-uploads] [data-remove]', function(){
			$(this).closest('div').remove();
		});
	}

	if( window.File && window.FileList && window.FileReader ){
		Init();
	}

})();